import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import { useVisibilityHook } from "react-observer-api";
import CountUp from "react-countup";
import Circle from "../components/animations/circle";
import Down from "../components/animations/down";
import Header from "../components/header";
import Layout from "../components/layout";
import Modal from "../components/s-modal";
import { Seo } from "../components/seo";
import DownloadBtn from "../images/download-btn.inline.svg";
import PlayIcon from "../images/playiconsmall.inline.svg";
import "../components/sustain.css";

function Sustainability({ data }) {
  const { setElement, isVisible } = useVisibilityHook();

  const consuptionPerSecond = 6341;
  const yearBeginningTimestamp = 1609452001;

  const currentTimestamp = new Date().getTime() / 1000;
  const energyConsumption = Math.ceil(
    (currentTimestamp - yearBeginningTimestamp) * consuptionPerSecond
  );

  var NextUid = data.prismicSustainability.uid;

  if (data.prismicSustainability.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicSustainability.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }
  return (
    <Layout htmllang={data.prismicSustainability.lang}>
      <Header
        htmllang={data.prismicSustainability.lang}
        langswitcher={NextUrl}
      />
      <Container className="fullwidth sustainheader">
        <GatsbyImage
          alt="Dagcoin"
          image={data.bg.childImageSharp.gatsbyImageData}
        />
        <div className="sustainheader-content">
          <Row>
            <Col>
              <h1>
                It's <strong>time to switch</strong> to cryptos that don’t
                require mining
              </h1>
              <p>
                Mining and carrying out transactions with most cryptocurrencies
                results in a gargantuan energy footprint, and due to the nature
                of blockchain, this is becoming a less manageable problem with
                each passing day.
              </p>
              <div className="button">
                <PlayIcon />
                Watch video <Modal />
              </div>
              <div className="scrolldownicon">
                <Down />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="fullwidth with-image">
        <Row>
          <Col>
            <div className="with-image-content">
              <h2>
                Our planet is at a <strong>breaking point</strong>
              </h2>
              <p>
                Ineffective and wasteful energy usage has led to a crisis in
                which we’re faced with catastrophic climate disasters, mass
                extinctions, and decaying natural habitats.
              </p>
            </div>
          </Col>
          <Col>
            <GatsbyImage
              alt="Dagcoin"
              image={data.planet.childImageSharp.gatsbyImageData}
            />
          </Col>
        </Row>
      </Container>
      <Container className="fullwidth counter-sustain">
        <Row>
          <Col>
            <h3>
              <div className="countern">
                <CountUp
                  start={energyConsumption}
                  end={energyConsumption * 2}
                  duration={63410000 * 3}
                  separator=","
                ></CountUp>
              </div>
            </h3>
            <p>The amount of energy used for crypto mining this year.</p>
            <h5>Source: BitcoinEnergyConsumption.com</h5>
          </Col>
        </Row>
      </Container>
      <Container className="fullwidth two-blocks">
        <Row>
          <Col>
            <div className="two-content">
              <h3>76%</h3>
              <p>of energy in developing countries is produced from wood.</p>
              <h5>Source: FAO.ORG</h5>
            </div>
          </Col>
          <Col>
            <div className="two-content">
              <h3>85M</h3>
              <p>tons of CO2 are emitted by crypto mining each year.</p>
              <h5>Source: fortune.com</h5>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        id="christmas-animation-1"
        className="fullwidth christmas"
        ref={setElement}
      >
        <Row>
          <Col>
            <h3>
              This Christmas, it's time to{" "}
              <strong>make a change{isVisible && <Circle />}</strong>
            </h3>
          </Col>
        </Row>
      </Container>
      <Container className="fullwidth snow">
        <Row>
          <Col>
            <GatsbyImage
              alt="Dagcoin"
              image={data.snow.childImageSharp.gatsbyImageData}
            />
          </Col>
          <Col>
            <div className="snow-content">
              <h3>
                Dagcoin — a <strong>sustainable cryptocurrency</strong>, built
                for a cleaner world.
              </h3>
              <p>
                This Christmas, consider switching to cryptocurrencies that
                have, and will always have, a minuscule ecological impact.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="fullwidth change">
        <h3>
          How we're <strong>making a change</strong>
        </h3>
        <Row>
          <Col>
            <GatsbyImage
              alt="Dagcoin"
              image={data.sone.childImageSharp.gatsbyImageData}
            />
          </Col>
          <Col>
            <div className="change-content">
              <h5>TECHNOLOGY</h5>
              <h4>
                Dagcoin <strong>doesn't require mining</strong> - and never
                will.
              </h4>
              <p>
                We’ve taken a different approach to confirming transactions on
                the DAG-chain. We use a proprietary system that is based on a
                small number of witnesses whom confirm transactions. Therefore,
                Dagcoin doesn’t require any kind of mining.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="reverse-mobile">
          <Col>
            <div className="change-content">
              <h5>ENERGY CONSUMPTION</h5>
              <h4>
                We use <strong>99.99% less energy</strong> than proof-of work
                cryptocurrencies.
              </h4>
              <p>
                As blockchain-based cryptocurrencies increase in usage, so does
                the required energy for each and every new transaction.This is
                not the case with Dagcoin, which requires a miniscule amount of
                energy that remains static, regardless of the number of
                transactions.
              </p>
            </div>
          </Col>
          <Col>
            <GatsbyImage
              alt="Dagcoin"
              image={data.stwo.childImageSharp.gatsbyImageData}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <GatsbyImage
              alt="Dagcoin"
              image={data.sthree.childImageSharp.gatsbyImageData}
            />
          </Col>
          <Col>
            <div className="change-content">
              <h5>SPONSORSHIPS</h5>
              <h4>
                We give a helping hand via{" "}
                <strong>environmental partnerships.</strong>
              </h4>
              <p>
                World Cleanup Day is an annual global social action program
                we're proud to sponsor. Aimed at combating the global solid
                waste problem, World Cleanup Day harnesses the power of everyday
                people to achieve incredible things in unity.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="fullwidth revolution">
        <Row data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
          <Col>
            <h3>
              Join the revolution and <strong>make a change</strong> too!
            </h3>
            <p>
              This Christmas, say goodbye to the ways of old, and make a
              meaningful impact.
              <br />
              Join the Dagcoin community!
            </p>
            <Link to="/dagcoin-wallet/">
              <DownloadBtn />
              Download DagWallet
            </Link>
          </Col>
          <GatsbyImage
            alt="Dagcoin"
            image={data.hand.childImageSharp.gatsbyImageData}
          />
        </Row>
      </Container>
    </Layout>
  );
}

export const Head = ({ data }) => {
  return (
    <>
      <Seo
        // TODO: Add description
        pathname={data.prismicSustainability.uid}
        // TODO: Title should come from "seo_title" query
        title="Sustainability"
        // lang={data.prismicSustainability.lang}
      />
    </>
  );
};

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicSustainability(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      lang
      type
      uid
      data {
        title {
          text
        }
      }
    }
    hand: file(relativePath: { eq: "hand-min.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    bg: file(relativePath: { eq: "sbg-min.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    planet: file(relativePath: { eq: "planet-min.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    snow: file(relativePath: { eq: "dagcoinsnow.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 99, layout: CONSTRAINED)
      }
    }
    sone: file(relativePath: { eq: "s1-min.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 99, layout: CONSTRAINED)
      }
    }
    stwo: file(relativePath: { eq: "s2-min.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 99, layout: CONSTRAINED)
      }
    }
    sthree: file(relativePath: { eq: "s3-min.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 99, layout: CONSTRAINED)
      }
    }
  }
`;
export default Sustainability;
