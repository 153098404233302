import React, { createRef } from "react";
import lottie from "lottie-web";
import animationData from "../animations/down.json";
const Lottie = () => {
  let animationContainer = createRef();

  React.useEffect((animationContainer) => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);
  return <div className="downanimation" ref={animationContainer}></div>;
};
export default Lottie;
