import React, { createRef } from "react";
import lottie from "lottie-web";
import animationData from "../animations/circle.json";

const Lottie = () => {
  let animationContainer = createRef();

  React.useEffect((animationContainer) => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animationData,
    });
  }, []);
  return <div className="animation" ref={animationContainer}></div>;
};
export default Lottie;
